import React, { useEffect, useState } from 'react'
import { useParams, Link, useLocation } from "react-router-dom"
import axios from 'axios'

import Visa from "../Assets/Images/PaymentLogo/visa.svg"
import VisaMaster from "../Assets/Images/PaymentLogo/visaMaster.png"

import Bkash from "../Assets/Images/PaymentLogo/bkash.svg"
import Nagad from "../Assets/Images/PaymentLogo/nagad.png"
import Rocket from "../Assets/Images/PaymentLogo/rocket.jpg"
import COD from "../Assets/Images/PaymentLogo/COD.png"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderConfirmationPage = () => {

    const { slug } = useParams()

    const [orderData, setOrderData] = useState(null)

    const { pathname } = useLocation()
    // console.log(pathname)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        if (slug) {
            axios.get(`/orders/${slug}`, {
                headers: {
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setOrderData(response.data)
            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }, [slug])

    const handlePaymentWithSSLCOMERZ = () => {
        const toastId = toast.info(<b>Payment processing....</b>, {
            autoClose: 5000,
        })
        axios.post("/payment-sslcommerz", {
            order_id: orderData.id
        }, {
            headers: {
                Accept: 'application/json',
            }
        }).then(response => {
            toast.dismiss(toastId);
            console.log(response)
            window.location.replace(response.data.GatewayPageURL)
        }).catch(errors => {
            console.log(errors.response)
        })
    }

    const handleCODPayment = () => {
        const toastId = toast.info(<b>Payment processing....</b>, {
            autoClose: 5000,
        })
        axios.post("/payment-cod", {
            order_id: orderData.id
        }, {
            headers: {
                Accept: 'application/json',
            }
        }).then(response => {
            toast.dismiss(toastId);
            console.log(response)
            setOrderData(response.data.order)
            toast.success(<b>Cash On Delivery Method Selected</b>, {
                autoClose: 2000,
            })
        }).catch(errors => {
            console.log(errors.response)
        })
    }




    return (
        <div className="container mx-auto pl-4 pr-4 md:pr-7">

            <div className="mt-6">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Order Summary</li>
                </ol>
            </div>

            <div className="h-20 mt-4 flex justify-center">
                <div>
                    <p className="font-Poppins text-base font-medium text-center">Order Number <span className="font-bold">{slug}</span></p>
                    {
                        orderData && orderData.set_paid == false && orderData.payment_method == "" ? (
                            <p className="font-Poppins text-base">Thank you for Shopping with <span className="text-logobarElementBG font-medium">Fashion Field</span></p>
                        ) : (
                            <div>
                                {
                                    orderData?.payment_method == "sslcommerz" && <p className="font-Poppins text-base"><span className="text-logobarElementBG font-medium">Thanks for the payment</span> your order is on your way.</p>
                                }
                                {
                                    orderData?.payment_method == "cod" && <p>Thanks for selecting CASH ON DELIVERY Option</p>
                                }
                            </div>
                        )
                    }
                </div>
            </div>

            {
                orderData && <div className="mt-4 mb-10 flex flex-col items-center justify-center">
                    {
                        orderData.status == "Processing" && orderData.set_paid == false && orderData.payment_method == "" ? (
                            <div className='w-full'>
                                <p className="text-center font-bold text-xl text-logobarElementBG">Pay with</p>
                                <div className='h-40 flex justify-evenly w-full'>

                                    <div className="mt-3 cursor-pointer h-full w-40" onClick={handleCODPayment}>
                                        <img src={COD} className='h-full w-full object-contain' />
                                    </div>

                                    <div className="mt-3 cursor-pointer h-full w-40" onClick={handlePaymentWithSSLCOMERZ}>
                                        <img src={VisaMaster} className='h-full w-full object-contain' />
                                    </div>

                                    <div className="mt-3 cursor-pointer h-full w-40">
                                        <img src={Bkash} className='h-full w-full object-contain' />
                                    </div>

                                    <div className="mt-3 cursor-pointer h-full w-40">
                                        <img src={Nagad} className='h-full w-full object-contain' />
                                    </div>

                                    <div className="mt-3 cursor-pointer h-full w-40">
                                        <img src={Rocket} className='h-full w-full object-contain' />
                                    </div>

                                </div>
                            </div>
                        ) : <div>
                            {
                                orderData?.payment_method == "sslcommerz" && <p className='text-center font-bold text-xl text-logobarElementBG'>Money is successfully paid</p>
                            }
                            {
                                orderData?.payment_method == "cod" && <p className='text-center font-bold text-xl text-logobarElementBG'>Please pay to the Delivery Man when the product is in your DOORSTEP</p>
                            }
                        </div>
                    }
                </div>
            }

            <div className="grid grid-cols-2 gap-4 mt-4">

                <div className="">
                    <div className="flex flex-col items-center">
                        <p className="mb-4">Delivery Address</p>
                        {orderData && <p>{`${orderData.shipping.address}, ${orderData.shipping.area}, ${orderData.shipping.city}, ${orderData.shipping.region}`}</p>}
                        {orderData && <p>Mobile Number: {orderData.shipping.phone}</p>}
                    </div>
                </div>

                <div className="">
                    <div className="flex flex-col">
                        <p className="text-center mb-4">Order Summary</p>

                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Sub-Total</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.sub_total}</p>}
                            </div>

                        </div>

                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Shipping Cost</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.shipping_total}</p>}
                            </div>

                        </div>


                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Total Amount To Be Paid</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.total}</p>}
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        </div>
    )
}

export default OrderConfirmationPage
