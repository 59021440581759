import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"

import HomeAddress from './HomeAddress'
import OfficeAddress from './OfficeAddress'
import GiftAddress from './GiftAddress'

const BillingAddress = () => {

    const { token } = useSelector(state => state.authInfo)

    const [districtOptionsAxios, setDistrictOptionsAxios] = useState([])
    const [districtOptionsCustom, setDistrictOptionsCustom] = useState([])

    // getting and making the options list for district
    useEffect(() => {
        // if (token != null) {
        axios.get("/address/get-regions", {
            headers: {
                // Authorization: "Bearer " + token,
                Accept: 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            setDistrictOptionsAxios(response.data)
        }).catch(errors => {
            console.log(errors.response)
        })
        // }
    }, [])

    useEffect(() => {
        setDistrictOptionsCustom([])
        if (districtOptionsAxios.length > 0) {
            districtOptionsAxios.map((item, index) => {
                setDistrictOptionsCustom(prevState => [...prevState, { value: item.id, label: item.name }])
            })
        }

    }, [districtOptionsAxios])

    const [openHome, setOpenHome] = useState(false)
    const [openOffice, setOpenOffice] = useState(false)
    const [openGift, setOpenGift] = useState(false)

    useEffect(() => {
        if (openHome) {
            setOpenOffice(false)
            setOpenGift(false)
        }
    }, [openHome])

    useEffect(() => {
        if (openOffice) {
            setOpenHome(false)
            setOpenGift(false)
        }
    }, [openOffice])

    useEffect(() => {
        if (openGift) {
            setOpenHome(false)
            setOpenOffice(false)
        }
    }, [openGift])




    return (
        <div className="argha billing-address-pannel">

            <div className='flex justify-between mb-8'>
                <div className="flex items-center changed-checkbox">
                    <input type="checkbox" id="home-address" checked={openHome} onChange={() => setOpenHome(prevState => !prevState)} />
                    <label className="font-Poppins font-medium text-base ml-2" htmlFor="home-address">Home Address</label>
                </div>

                <div className="flex items-center changed-checkbox">
                    <input type="checkbox" id="office-address" checked={openOffice} onChange={() => setOpenOffice(prevState => !prevState)} />
                    <label className="font-Poppins font-medium text-base ml-2" htmlFor="office-address">Office Address</label>
                </div>

                <div className="flex items-center changed-checkbox">
                    <input type="checkbox" id="gift-address" checked={openGift} onChange={() => setOpenGift(prevState => !prevState)} />
                    <label className="font-Poppins font-medium text-base ml-2" htmlFor="gift-address">Gift Address</label>
                </div>
            </div>

            {
                openHome && <HomeAddress openHome={openHome} setOpenHome={setOpenHome} districtOptionsCustom={districtOptionsCustom} />
            }

            {
                openOffice && <OfficeAddress openOffice={openOffice} setOpenOffice={setOpenOffice} districtOptionsCustom={districtOptionsCustom} />
            }

            {
                openGift && <GiftAddress openGift={openGift} setOpenGift={setOpenGift} districtOptionsCustom={districtOptionsCustom} />
            }

        </div>
    )
}

export default BillingAddress
